const CloudViewDefaultState = {
    activeCloudDetail: null,
    viewMode: true,
  },
  cloudViewReducer = (state = CloudViewDefaultState, action) => {
    switch (action.type) {
      case "ACTIVE_CLOUD_DETAIL": {
        return Object.assign({}, state, {
          activeCloudDetail: action.activeCloudDetail,
        });
      }
      case "VIEW_MODE": {
        return Object.assign({}, state, { viewMode: action.viewMode });
      }
      default:
        return state;
    }
  };

export { CloudViewDefaultState, cloudViewReducer };
